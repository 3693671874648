function sendCustomerAnalyticEvent(eventName, eventAttributes={}){
    webengage.track(eventName, eventAttributes);
}
window.sendCustomerAnalyticEvent = sendCustomerAnalyticEvent;

const customerAnalyticEvents = {
    design_form_clicked: "Design Form Clicked",
    design_order_created: "Design Order Created",
    garden_order_created: "Garden Order Created",
    garden_care_form_clicked: "Garden Care Form Clicked",
    garden_care_order_created: "Garden Care Order Created",
    order_completed: "Order Completed",
    search_bar_input: "Search Bar Input",
    partner_register_clicked: "Partner Register Clicked",
    encyclopedia_clicked: "Encyclopedia Clicked",
    contact_us_clicked: "Contact us Clicked",
    garden_detail_viewed: "Garden Detail Viewed",
    design_detail_viewed: "Design Detail Viewed",
    brochure_downloaded: "Brochure Downloaded",
};
window.customerAnalyticEvents = customerAnalyticEvents
