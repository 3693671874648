const appUrl = window.location.origin;

function isValidUrl(string) {
    try {
        new URL(string);
        return true;
    } catch (error) {
        return false;
    }
}
window.isValidUrl = isValidUrl;

function openUrl(url, newTab = true) {
    if (!isValidUrl(url)) {
        console.error('url is not valid');
        return false;
    }

    if (newTab) {
        window.open(url, '_blank');
    } else {
        window.location.href = url;
    }
}
window.openUrl = openUrl;

function replaceCurrentDomainByAppUrl() {
    const path = window.location.pathname;
    const query = window.location.search;

    return appUrl + path + query;
}
window.replaceCurrentDomainByAppUrl = replaceCurrentDomainByAppUrl;

function replaceDomainByAppUrl(fullUrl = window.location) {
    const url = new URL(fullUrl);

    const path = url.pathname;
    const query = url.search;

    return appUrl + path + query;
}
window.replaceDomainByAppUrl = replaceDomainByAppUrl;

function isInLoginPage(fullUrl = window.location) {
    const loginPagePaths = {
        "/login": true,
        "/admin-login": true,
        "/freelancer-login": true,
        "/gardener-login": true,
    };

    return loginPagePaths.hasOwnProperty(fullUrl.pathname);
}
window.isInLoginPage = isInLoginPage;

function getQueryParameterValue(key) {
    const currentUrl = window.location.href;

    const url = new URL(currentUrl);

    const params = new URLSearchParams(url.search);

    const value = params.get(key);

    return value;
}

export { replaceCurrentDomainByAppUrl, replaceDomainByAppUrl, getQueryParameterValue, isInLoginPage, isValidUrl };
