import * as bowser from './bowser';
import * as inAppSpy from './inAppSpy';
import { replaceCurrentDomainByAppUrl, getQueryParameterValue, isInLoginPage, replaceDomainByAppUrl, isValidUrl } from './urlHelper';
import { getRedirectAfterLoginSession } from './sessionStorageHelper';

const appUrl = window.location.origin;

function forceInAppToUseBrowser() {
    const isInApp = inAppSpy.isInApp();

    if (!isInApp) {
        return;
    }

    const alreadyRedirected = getQueryParameterValue('redirected') === 'true';
    if (alreadyRedirected) {
        return;
    }

    const isAndroid = bowser.isAndroid();
    const isIos = bowser.isIos();

    const redirectAfterLoginSession = getRedirectAfterLoginSession();
    let destinationFullUrl = replaceCurrentDomainByAppUrl();

    if (isInLoginPage() && redirectAfterLoginSession && isValidUrl(redirectAfterLoginSession)) {
        destinationFullUrl = replaceDomainByAppUrl(redirectAfterLoginSession);
    }

    let redirectBrowserUrl = null;

    if (isAndroid) {
        redirectBrowserUrl = `intent:${destinationFullUrl}?redirected=true#Intent;package=com.android.chrome;end`;
    }

    if (isIos) {
        redirectBrowserUrl = `shortcuts://x-callback-url/run-shortcut?name=${crypto.randomUUID()}&x-error=${encodeURIComponent(destinationFullUrl)}`;
    }

    const forceBrowserA = document.createElement('a');
    forceBrowserA.href = redirectBrowserUrl;
    document.body.appendChild(forceBrowserA);

    forceBrowserA.click();
}
window.forceInAppToUseBrowser = forceInAppToUseBrowser;