const axios = require('axios');
const JSZip = require('jszip');
const { saveAs } = require('file-saver');

async function downloadImagesAsZip(imageUrls, zipFileName = 'images.zip') {
    try {
        const zip = new JSZip();

        for (const imageUrl of imageUrls) {
            const response = await axios.get(imageUrl, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json', // for chrome to trigger preflight options request so it not get cors
                },
            });

            const imageName = getFileNameFromUrl(imageUrl);
            zip.file(imageName, response.data);
        }

        const zipContent = await zip.generateAsync({ type: 'blob' });

        saveAs(zipContent, zipFileName);
    } catch (error) {
        console.error('Error downloading image or creating ZIP:', error);
    }
}

window.downloadImagesAsZip = downloadImagesAsZip;
