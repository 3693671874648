function putFileToInputElement(file, elementId, triggerChange = true) {
    const element = document.getElementById(elementId);

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    element.files = dataTransfer.files;

    if (triggerChange) {
        const event = new Event('change', { bubbles: true });
        element.dispatchEvent(event);
    }
}
window.putFileToInputElement = putFileToInputElement;

function getFileNameFromUrl(url) {
    const urlObject = new URL(url);

    return urlObject.pathname.split('/').pop();
}
window.getFileNameFromUrl = getFileNameFromUrl;

function getFileFormatFromFileName(fileName) {
    return fileName.split('.').pop();
}
window.getFileFormatFromFileName = getFileFormatFromFileName;

function convertBytesToMegaBytes(bytes) {
    return bytes/1048576;
}
window.convertBytesToMegaBytes = convertBytesToMegaBytes;

function convertBytesToKiloBytes(bytes) {
    return bytes/1024;
}
window.convertBytesToKiloBytes = convertBytesToKiloBytes;

async function getFileSize(url, format = 'B') {
    try {
        const response = await fetch(url, { method: 'HEAD' });
        const fileSize = response.headers.get('Content-Length');

        if (! fileSize) {
            console.error("File size not available.");
        }

        if (format == 'KB') {
            return convertBytesToKiloBytes(fileSize);
        } else if (format == 'MB') {
            return convertBytesToMegaBytes(fileSize);
        }

        return fileSize;
    } catch (error) {
        console.error("Error fetching file size:", error);
    }
}
window.getFileSize = getFileSize;


