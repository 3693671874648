import { removeDecimal, removeNonInteger, removeNonNumber, removeLeadingZero } from './numberHelper';

function sanitizeIDRCurrencyValue(value) {
    if (!value || value == 0) {
        return 0;
    }

    let sanitizedValue = value.toString();

    sanitizedValue = removeNonNumber(sanitizedValue);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    if (!sanitizedValue && sanitizedValue.length > 1) {
        return 0;
    }

    sanitizedValue = removeLeadingZero(sanitizedValue);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    sanitizedValue = removeDecimal(sanitizedValue);

    return sanitizedValue;
}
window.sanitizeIDRCurrencyValue = sanitizeIDRCurrencyValue;

function sanitizeDiscountValue(value) {
    let sanitizedValue = sanitizeVolumeValue(value);

    if (sanitizedValue > 100) {
        sanitizedValue = 100;
    }

    return sanitizedValue;
}
window.sanitizeDiscountValue = sanitizeDiscountValue;

function sanitizeVolumeValue(value) {
    if (!value || isNaN(value)) {
        return 0;
    }

    let sanitizedValue = removeNonNumber(value.toString());

    if (sanitizedValue.charAt(sanitizedValue.length - 1) === '.') {
        return sanitizedValue;
    }

    sanitizedValue = removeLeadingZero(sanitizedValue);

    if (!sanitizedValue || isNaN(sanitizedValue)) {
        return 0;
    }

    return parseFloat(sanitizedValue);
}
window.sanitizeVolumeValue = sanitizeVolumeValue;

function togglePasswordVisibility(inputId, idIcon) {
    const newPassword = document.getElementById(inputId);
    const inputType = newPassword.getAttribute('type');
    const toggledInputType = (inputType === 'password') ? 'text' : 'password';
    newPassword.setAttribute('type', toggledInputType);

    const togglePasswordIcon = document.getElementById(idIcon);
    if (toggledInputType === 'password') {
        togglePasswordIcon.classList.remove('mdi-eye-off');
        togglePasswordIcon.classList.add('mdi-eye');
    } else {
        togglePasswordIcon.classList.remove('mdi-eye');
        togglePasswordIcon.classList.add('mdi-eye-off');
    }
}
window.togglePasswordVisibility = togglePasswordVisibility;
